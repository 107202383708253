// src/components/Navbar.js
import React from 'react';
import './Navbar.css';
import profilePic from '../assets/profile.jpg'; // Import profile picture
import logo from '../assets/logo.png'; // Import the logo

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-title">
        {/* Logo and Title */}
        <img src={logo} alt="UMGC Logo" className="logo" />
        <h1>UMGC Pace 111B - Professor Durepo</h1>
      </div>

      <div className="profile-section">
        {/* Profile Section */}
        <span className="profile-name">Kory Kaai</span>
        <img src={profilePic} alt="Profile" className="profile-pic" />
      </div>
    </nav>
  );
};

export default Navbar;
