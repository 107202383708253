import React from 'react';
import styles from './UnitDisplay.module.css'; // Using CSS Modules

const UnitDisplay = ({ unitTitle, content, imageSrc }) => {
  return (
    <div className={styles['unit-display']}>
      <h2 className={styles['unitHeader']}>{unitTitle}</h2>
      <p className={styles['unitText']}>{content}</p>

      {/* Render the image below the content if `imageSrc` exists */}
      {imageSrc && (
        <div className={styles['unit-image-container']}>
          <img
            src={imageSrc}
            alt={unitTitle} // No comment inside JSX
            className={styles['unit-image']}
          />
        </div>
      )}
    </div>
  );
};

export default UnitDisplay;
