import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import UnitDisplay from './components/UnitDisplay';
import MotivationModal from './components/MotivationModal';
import './App.css';
import imPossibleImage1 from './assets/im-possible.png'; // Placeholder image for all units
import imPossibleImage2 from './assets/goals-motivations.png';
import imPossibleImage3 from './assets/my-big-rock.png';
import imPossibleImage4 from './assets/success-network.png';
import imPossibleImage5 from './assets/informational-interview.png';
import imPossibleImage6 from './assets/networking-teamwork.png';
import imPossibleImage7 from './assets/support-services.png';
import imPossibleImage8 from './assets/daacs.png';

const units = [
  {
    id: 'unit1',
    title: 'Unit 1: Introduction & Mindset',
    content: 'When I first signed up for PACE 111b, I wasn’t exactly sure what to expect. A class about succeeding academically and professionally? It sounded helpful, but honestly, I figured it would just cover stuff I’d heard before. Turns out, I couldn’t have been more wrong. This course wasn’t just about lectures and assignments—it handed me tools I didn’t even know I needed! One of the most powerful ones? The growth mindset.Let’s kick things off with Unit 1 and this whole “growth mindset” idea, which was a real eye-opener for me. I’d heard the buzzwords before—“positive thinking,” “growth”—but this time, it felt different. I learned about how a growth mindset is all about believing you can develop your abilities through effort, as opposed to a fixed mindset where you think you’re either born good at something or you’re not. This concept really hit home when I reflected on how, back in high school, I had a serious fixed mindset, especially in areas like art. I avoided anything that felt like a challenge because I assumed I just wasn’t “naturally talented.” Looking back, I can see how that mentality held me back and stunted my growth. But now? I get it. Challenges aren’t these giant, immovable obstacles—they’re more like puzzles waiting to be solved. This shift in thinking has already changed how I approach tough situations, both academically and personally. Instead of shying away from something because it feels hard, I lean in and remind myself that it’s all part of the learning process. The more effort I put in, the better I’ll get. It’s wild to think that something as simple as changing how you view challenges can make such a huge difference. This growth mindset has basically become my new secret weapon for staying resilient when things get tough.',
    imageSrc: imPossibleImage1 // Image for Unit 1
  },
  {
    id: 'unit2',
    title: 'Unit 2: Goals & Motivations',
    content: `Before PACE 111b, I always had this vague sense of wanting to help others and make a difference, but I never really understood where that drive came from or how to channel it. In Unit 2, though, something clicked. I realized that my passion for helping others is deeply tied to the struggles I’ve faced and the hurdles I’ve overcome in my own life. Suddenly, my goals didn’t feel abstract anymore—they became more concrete, more personal. They weren’t just dreams; they were a map guiding me on a clear path forward.
Let’s be honest—goal-setting can feel overwhelming, right? You’ve got these huge dreams, and it’s easy to feel stuck, not knowing where to start. But Unit 2 swooped in and saved the day with the SMART goals framework. If you’re not familiar, SMART stands for Specific, Measurable, Achievable, Relevant, and Time-bound. Basically, it’s the blueprint for turning those big, intimidating goals into something manageable. Now, instead of vaguely saying, “I want to help people,” I’ve broken it down into smaller, actionable steps, like “I’ll complete a volunteering project this semester,” or “I’ll network with professionals in my field.”
These smaller steps have been a game-changer. They make the bigger picture feel less like I’m trying to scale a mountain and more like I’m walking up a flight of stairs—one solid step at a time. Each move, no matter how small, is getting me closer to where I want to be, and more importantly, it’s helping me make the kind of positive impact I’ve always wanted. Now, I’m not just chasing some distant dream; I’m actually living the process, step by step.`,
    imageSrc: imPossibleImage2 // Placeholder image for Unit 2
  },
  {
    id: 'unit3',
    title: 'Unit 3: What Are Your Big Rocks?',
    content: `Unit 3 was a real eye-opener for me, and honestly, it might have been the one that saved my sanity. This unit focused on identifying your “Big Rocks,” the key priorities that should come first in your life. It made me step back and really think about what matters most. During my time in the military, my work always took priority, and as a result, I found myself disconnected from my family. It felt like I was constantly juggling everything, but nothing was really getting the attention it deserved. That left me burned out and frustrated.
The “What Are Your Big Rocks?” discussion helped me take a hard look at my priorities. I realized I was pouring my energy into things that didn’t truly matter, while the most important parts of my life—like family, health, and personal growth—were taking a backseat. That’s when things shifted for me. I started scheduling my Big Rocks first—those non-negotiable things like spending time with family, taking care of my health, and working on personal development—before letting all the smaller, less important stuff fill up my day.
Now, I feel like I’ve got a better handle on balancing school, work, and personal life. I’m no longer drowning in to-do lists or feeling like I’m constantly playing catch-up. Sure, it’s not perfect, and some days are still chaotic, but focusing on my Big Rocks has made a huge difference. It’s helped me create a life where I’m not just productive—I’m more present, more connected, and definitely more at peace with how I spend my time.`,
    imageSrc: imPossibleImage3 // Placeholder image for Unit 3
  },
  {
    id: 'unit4',
    title: 'Unit 4: Engage with Your Success Network',
    content: `Unit 4 was a big turning point for me when it came to understanding the power of my Success Network. You know that moment when you realize you don’t actually have to do everything by yourself? Yeah, that was me during this unit. I’ve always had this stubborn streak where I felt like I needed to handle things on my own, whether it was school, work, or figuring out my career path. But this unit made me realize that asking for help doesn’t mean you’re weak—it means you’re smart.
I learned how to leverage my Success Network for academic and career guidance, which was something I hadn’t fully tapped into before. Academic advisors, professors, career services, even peers—there’s this whole team of people ready to support me. Since then, I’ve made it a point to actively engage with them. Whether it’s planning my courses, setting realistic academic goals, or just getting advice on how to juggle everything, having that network has been a game-changer.
Now, I’m way more proactive about reaching out for help. I schedule regular meetings with my mentors and advisors, and it’s given me a clearer roadmap for both my academic journey and career goals. The funny thing? I’ve found that people are more than happy to help—you just have to ask. This lesson is one I’ll be carrying with me long after I leave school, as it’s taught me that success isn’t something you achieve alone; it’s something you build with the support of those around you.`,
    imageSrc: imPossibleImage4 // Placeholder image for Unit 4
  },
  {
    id: 'unit5',
    title: 'Unit 5: Preparing for Your Informational Interview',
    content: `Networking used to make me super nervous. The thought of walking up to someone in my field and striking up a conversation felt like stepping into total intimidation territory. But Unit 5, with its focus on informational interviews, completely shifted my perspective. Before this unit, I didn’t really know where to start or how to make these conversations meaningful. But the Informational Interview Assignment broke it down for me, and suddenly, it didn’t feel so scary anymore.
I learned how to choose the right person to interview, someone who could offer real insights into my career field. On top of that, I figured out how to research the current trends that are shaping the business world—things like digital transformation, sustainability, and the rise of hybrid work models. With this knowledge, I came up with thoughtful questions that could help me dig deeper into the conversation, instead of just scratching the surface with generic small talk.
Honestly, this unit gave me the confidence to not only reach out to professionals but also to approach the whole process with purpose. It’s no longer just about "networking" for the sake of it; it’s about staying informed and making meaningful connections. Who knew that asking someone in my field for an interview—or even just coffee—could actually be interesting and, dare I say, fun? Now, I’m excited to build these relationships and learn from people who are already where I want to be. This skill will be invaluable as I continue navigating my career journey.`,
    imageSrc: imPossibleImage5 // Placeholder image for Unit 5
  },
  {
    id: 'unit6',
    title: 'Unit 6: Networking & Teamwork',
    content: `I’ve always been a bit of a lone wolf, preferring to handle things on my own rather than rely on others. But Unit 6 really made me rethink that whole approach. This unit was all about the power of teamwork and networking, and how they’re key to achieving professional success. It finally clicked for me—collaborating isn’t just about splitting up tasks; it’s about combining ideas, strengths, and perspectives to create something better than what you could do solo.
Reflecting on my experience leading a team during military deployment preparation, I realized just how crucial clear communication and positive reinforcement are for fostering strong teamwork. Back then, I thought leadership was about control—making sure everything went according to plan. But this unit helped me see that true leadership is about influence, not control. It’s about building trust, motivating people, and creating an environment where everyone feels valued and heard.
Since going through this unit, I’ve made a real effort to step out of my “lone wolf” mentality. I’ve started reaching out more, joining study groups, and engaging with people I might not have before. The biggest thing I’ve learned is that when you build trust and encourage collaboration, the team’s collective strength far outweighs what any individual can bring to the table alone. Whether it’s in a study group, work project, or a professional setting, teamwork doesn’t just matter—it’s essential. I’ve seen firsthand that when everyone contributes, the results are better, stronger, and way more impactful.`,
    imageSrc: imPossibleImage6 // Placeholder image for Unit 6
  },
  {
    id: 'unit7',
    title: 'Unit 7: Support Services Summary',
    content: `When you’re balancing school, work, and life, it’s easy to feel like you’re stranded on your own little island, trying to figure everything out by yourself. But Unit 7 was a much-needed reminder that I don’t have to go at it alone—UMGC has a whole network of support services ready to help. Whether it’s the UMGC Library, Career Services, or the Success Network, there are so many resources available to keep me on track with my studies, research assignments, and even career planning.
Before this unit, I didn’t fully appreciate how much these services could actually help me. I had this mindset that I needed to tough it out and handle things on my own. But now? I see these resources as my safety net—there whenever I start to feel overwhelmed or stuck. From tutoring sessions to career guidance, these services are designed to help me succeed, and I plan to take full advantage of them throughout my academic journey.
Unit 7 taught me that it’s not just okay to ask for help—it’s smart. Now, I’m more proactive about reaching out and making use of these tools whenever I need them. Knowing that I have this kind of support behind me has made me feel more confident and prepared to tackle whatever challenges come my way. It’s like having a whole team in my corner that I didn’t even realize was there.`,
    imageSrc: imPossibleImage7 // Placeholder image for Unit 7
  },
  {
    id: 'unit8',
    title: 'Unit 8: Reflecting on DAACS',
    content: `Unit 8: Reflecting on DAACS

Unit 8 was all about reflection—taking a good, hard look at my performance through the DAACS self-assessment. I’ve got to say, it was pretty eye-opening. Through this process, I didn’t just learn about where I stand academically; I discovered how I could manage my time better, set more realistic goals, and actually track my progress using self-regulation techniques.
Before this, I’d sometimes set goals that were too ambitious or just wing it when it came to balancing my responsibilities, which, unsurprisingly, led to a lot of stress and scrambling. But after going through DAACS, I’ve started breaking things down into manageable pieces. It’s like I’ve got this new set of tools to help me stay on top of things without feeling completely overwhelmed.
Now, I have a clearer sense of how to juggle my schoolwork, job, and personal life, and I know how to keep myself accountable along the way. These self-management skills aren’t just things I’ll use during this semester—they’re going to be crucial as I continue my academic journey and tackle whatever life throws my way.`,
    imageSrc: imPossibleImage8 // Placeholder image for Unit 8
  },
  {
    id: 'final',
    title: 'My Final Thoughts',
    content: `When I think about everything, I’ve learned in PACE 111b, it feels like I’ve put together a solid toolkit that’ll help me navigate both my academic and professional life. From adopting a growth mindset to mastering time management, from setting clear, realistic goals to leaning on my network—I’ve learned strategies that are going to stick with me long after this course is over. I’m walking away from this class feeling more prepared, more confident, and more excited to take on whatever comes next. Honestly, I feel like I’m not just ready to tackle my studies—I’m ready to tackle life.`,
    
  }
];

const App = () => {
  const [activeUnit, setActiveUnit] = useState(null);
  const [isMobile, setIsMobile] = useState(false); // Track if the screen is mobile-sized
  const [showModal, setShowModal] = useState(false); // Control the quote modal

  // Check if the device is mobile-sized based on window width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial state
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleUnitClick = (unitId) => {
    setActiveUnit(unitId === activeUnit ? null : unitId); // Toggle active unit
  };

  const handleBubbleClick = () => {
    setShowModal(true); // Show the motivation modal when the bubble is clicked
  };

  const closeModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <div className="App">
      <Navbar />

      <div className="container">
        {/* Sidebar or Accordion based on screen size */}
        <div className="sidebar">
          {isMobile ? (
            // Accordion style for mobile
            units.map((unit) => (
              <div key={unit.id}>
                <div
                  className={`sidebar-item ${activeUnit === unit.id ? 'active' : ''}`}
                  onClick={() => handleUnitClick(unit.id)}
                >
                  {unit.title}
                </div>
                {activeUnit === unit.id && (
                  <div className="accordion-content">
                    <UnitDisplay
                      unitTitle={unit.title}
                      content={unit.content}
                      imageSrc={unit.imageSrc} // Pass the imageSrc to UnitDisplay
                    />
                  </div>
                )}
              </div>
            ))
          ) : (
            // Sidebar style for desktop
            <div>
              {units.map((unit) => (
                <div
                  key={unit.id}
                  className={`sidebar-item ${activeUnit === unit.id ? 'active' : ''}`}
                  onClick={() => setActiveUnit(unit.id)}
                >
                  {unit.title}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Content area for desktop */}
        <div className="content">
          {!activeUnit ? (
            <div className="welcome-message">
              <h2>Welcome to Kory Kaai's Unit 8 Assignment (Lessons Learned)</h2>
              <p>Click on a unit to the left to get started.</p>

              {/* YouTube Video Embed */}
              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/qA0APsKdaYY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          ) : (
            <UnitDisplay
              unitTitle={units.find((unit) => unit.id === activeUnit)?.title}
              content={units.find((unit) => unit.id === activeUnit)?.content}
              imageSrc={units.find((unit) => unit.id === activeUnit)?.imageSrc} // Pass imageSrc
            />
          )}
        </div>

        {/* Motivation Modal */}
        {showModal && <MotivationModal closeModal={closeModal} />}

        {/* Motivation bubble */}
        <div
          className={isMobile ? 'motivation-bubble-mobile' : 'motivation-bubble-desktop'}
          onClick={handleBubbleClick}
        >
          Need motivation?
        </div>
      </div>
    </div>
  );
};

export default App;
