import React, { useState, useRef } from 'react';
import styles from './MotivationModal.module.css';
import { FaTimes } from 'react-icons/fa';
import { saveAs } from 'file-saver';

const quotes = [
  'Success is not final, failure is not fatal: It is the courage to continue that counts. - Winston Churchill',
  'It is better to fail in originality than to succeed in imitation. - Herman Melville',
  'Success is not final, failure is not fatal: It is the courage to continue that counts. — Winston Churchill',
'The only limit to our realization of tomorrow is our doubts of today. — Franklin D. Roosevelt,',
'Don’t watch the clock; do what it does. Keep going. — Sam Levenson',
'Success usually comes to those who are too busy to be looking for it. — Henry David Thoreau',
'I find that the harder I work, the more luck I seem to have. — Thomas Jefferson',
'Success is walking from failure to failure with no loss of enthusiasm. — Winston Churchill',
'The way to get started is to quit talking and begin doing. — Walt Disney',
'Your time is limited, don’t waste it living someone else’s life. — Steve Jobs',
'Fall seven times and stand up eight. — Japanese Proverb',
'It is not the strongest of the species that survive, nor the most intelligent, but the one most responsive to change. — Charles Darwin',
'Success is not how high you have climbed, but how you make a positive difference to the world. — Roy T. Bennett',
'Don’t be afraid to give up the good to go for the great. — John D. Rockefeller',
'Success is not in what you have, but who you are. — Bo Bennett',
'Success seems to be connected with action. Successful people keep moving. They make mistakes but they don’t quit. — Conrad Hilton',
'Motivation is what gets you started. Habit is what keeps you going. — Jim Rohn',
'Ambition is the path to success. Persistence is the vehicle you arrive in. — Bill Bradley',
'Success is the sum of small efforts, repeated day-in and day-out. — Robert Collier',
'Opportunities don’t happen. You create them. — Chris Grosser',
'I attribute my success to this: I never gave or took any excuse. — Florence Nightingale',
'Success is getting what you want, happiness is wanting what you get. — W.P. Kinsella',
'The secret of success is to do the common thing uncommonly well. — John D. Rockefeller Jr.',
'Success depends upon previous preparation, and without such preparation there is sure to be failure. — Confucius',
'If you really look closely, most overnight successes took a long time. — Steve Jobs',
'The best revenge is massive success. — Frank Sinatra',
'Success is how high you bounce when you hit bottom. — George S. Patton',
'Try not to become a man of success, but rather try to become a man of value. — Albert Einstein',
'The successful warrior is the average man, with laser-like focus. — Bruce Lee',
'Success is simple. Do what’s right, the right way, at the right time. — Arnold H. Glasow',
'I never dreamed about success, I worked for it. — Estee Lauder',
'It is better to fail in originality than to succeed in imitation. — Herman Melville',
'The road to success and the road to failure are almost exactly the same. — Colin R. Davis',
'Success is largely a matter of holding on after others have let go. — William Feather',
'The successful man will profit from his mistakes and try again in a different way. — Dale Carnegie',
'Success is the ability to go from one failure to another with no loss of enthusiasm. — Winston Churchill',
'In order to succeed, your desire for success should be greater than your fear of failure. — Bill Cosby',
'Success is liking yourself, liking what you do, and liking how you do it. — Maya Angelou',
'Success isn’t just about what you accomplish in your life; it’s about what you inspire others to do. — Unknown',
'Success is not to be pursued; it is to be attracted by the person you become. — Jim Rohn',
'Success is nothing more than a few simple disciplines, practiced every day. — Jim Rohn',
'Success doesn’t come from what you do occasionally, it comes from what you do consistently. — Marie Forleo',
'Success is about creating value, not making money. — Henry Ford',
'The only place where success comes before work is in the dictionary. — Vidal Sassoon',
'Some people dream of success, while other people get up every morning and make it happen. — Wayne Huizenga',
'Success is best when it’s shared. — Howard Schultz',
  // Add other quotes here...
];

// Function to generate a random dark background color
const getRandomDarkColor = () => {
  const letters = '012345'; // Darker colors only
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 6)]; // Only dark color values
  }
  return color;
};

const MotivationModal = ({ closeModal }) => {
  const [quote, setQuote] = useState('');
  const [imageGenerated, setImageGenerated] = useState(false); // Track if image has been generated
  const [error, setError] = useState(''); // Track any errors
  const canvasRef = useRef(null);

  // Function to generate a random quote
  const generateQuote = () => {
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote);
    setError(''); // Clear any previous error
  };

  // Function to generate the image with the quote and random background
  const generateImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const randomColor = getRandomDarkColor(); // Use dark background color

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Random dark background color
    ctx.fillStyle = randomColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // White text with the quote
    ctx.fillStyle = 'white';
    ctx.font = '24px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle'; // Ensures vertical centering

    const lines = quote.split(' - '); // Split the quote and the author

    // Break text into multiple lines if needed
    const maxWidth = canvas.width - 40; // Add padding for text width
    wrapText(ctx, `"${lines[0]}"`, canvas.width / 2, canvas.height / 2 - 20, maxWidth, 30);

    if (lines[1]) {
      ctx.fillText(`- ${lines[1]}`, canvas.width / 2, canvas.height / 2 + 40);
    }

    setImageGenerated(true); // Mark that image is generated
    setError(''); // Clear any previous error
  };

  // Function to save the image
  const saveImage = () => {
    if (!imageGenerated || !quote) { // Check if image is generated
      setError('Please generate a quote image before downloading.');
      return;
    }
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      saveAs(blob, 'motivational-quote.png');
    });
  };

  // Function to wrap text if it's too long
  const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
    const words = text.split(' ');
    let line = '';
    const lines = [];

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth && n > 0) {
        lines.push(line);
        line = words[n] + ' ';
      } else {
        line = testLine;
      }
    }
    lines.push(line);

    // Vertically center the text by adjusting y position
    const totalHeight = lines.length * lineHeight;
    let currentY = y - totalHeight / 2;

    for (let i = 0; i < lines.length; i++) {
      context.fillText(lines[i], x, currentY);
      currentY += lineHeight;
    }
  };

  return (
    <div>
      <div className={styles.modalOverlay} onClick={closeModal}></div>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={closeModal}>
          <FaTimes />
        </button>

        <h2>Your motivational quote!</h2>

        {/* Get a quote button */}
        <button className={styles.getQuoteButton} onClick={generateQuote}>
          Get a Quote
        </button>

        {/* Display the previewed quote */}
        {quote && <p className={styles.quotePreview}>{quote}</p>}

        {/* Generate the motivational image */}
        <button className={styles.motivateButton} onClick={generateImage}>
          Generate Motivation
        </button>

        {/* Canvas for rendering the image */}
        <canvas ref={canvasRef} width={500} height={200} className={styles.canvas}></canvas>

        {/* Download the generated image */}
        <button className={styles.downloadButton} onClick={saveImage}>
          Share/Download
        </button>

        {/* Error message */}
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </div>
  );
};

export default MotivationModal;
